@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "@style/form.scss";
::v-deep p {
  margin: 0;
}
.pay-tip {
  margin-left: 20px;
  color: #999999;
  font-size: 14px;
}
